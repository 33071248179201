<template>
  <div class="h-full flex flex-col">
    <h6 class="mb-10">Verify with Okra</h6>

    <div class="flex flex-col flex-grow">

      <div class="flex flex-col flex-grow items-center justify-center rounded p-5">
        <div class="bg-blue-200 grid h-20 mb-10 mx-auto place-items-center rounded-full text-blue-500 text-5xl w-20">
          <ion-icon name="alert-outline" />
        </div>
        <div class="font-medium text-center text-gray-600">
          Click the "Proceed" to begin.
        </div>
      </div>

      <div class="flex flex-col mt-10">
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <div class="flex gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray-soft btn-md relative"
            :disabled="form.loading"
            @click="back"
          >
            <span>Back</span>
            <i class="absolute inline-flex left-20px top-1/2" style="transform: translateY(-50%);">
              <ion-icon name="arrow-back-outline" />
            </i>
          </button>
          <okra
            class="w-full"
            @success.prevent="submit"
          >
            <button
              type="button"
              class="btn btn-blue btn-md relative w-full"
              :disabled="form.loading || isVerified"
            >
              <sm-loader v-if="form.loading" class="sm-loader-white" />
              <span v-else>Proceed</span>
              <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
                <ion-icon name="arrow-forward-outline" />
              </i>
            </button>
          </okra>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ManualUploadForm',
  props: {
    isVerified: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.$options.basicForm(),
    };
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    back() {
      this.$emit('back');
    },
    async submit({ clientId: code }) {
      this.form.error = null;
      this.form.setLoading();
      this.$emit('loading', true);
      await this.sendRequest('personal.onboarding.documents.okra', {
        data: { code },
        success: (response) => {
          const { data: { user, documents } } = response.data;
          this.updateUser({ ...user, documents })
            .then(() => {
              this.$emit('verify-okra', { user, documents });
            });
        },
        error: (error) => {
          this.form.error = error;
        },
      });
      this.form.setLoading(false);
      this.$emit('loading', false);
    },
  },
};
</script>
